@font-face {
	font-family: 'Actay Wide';
	src: url('../fonts/ActayWide-Bold.woff2') format('woff2'),
	url('../fonts/ActayWide-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: 'Inter';
	src: url('../fonts/Inter-Light.woff2') format('woff2'),
	url('../fonts/Inter-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Inter';
	src: url('../fonts/Inter-Regular.woff2') format('woff2'),
	url('../fonts/Inter-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Inter';
	src: url('../fonts/Inter-SemiBold.woff2') format('woff2'),
	url('../fonts/Inter-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}