$color-white: #fff;
$color-black: #000;
$color1: #1a9fff;

$font1: 'Actay Wide', sans-serif;
$font2: 'Inter', sans-serif;

// Sass Breakpoint Mixin
@mixin breakpoint($point) {
	@if $point == psm {
		@media (min-width: 480px) { @content; }
	}
	@else if $point == pmd {
		@media (min-width: 768px) { @content; }
	}
	@else if $point == plg {
		@media (min-width: 1000px) { @content; }
	}
	@else if $point == pxl {
		@media (min-width: 1240px) { @content; }
	}
	@else if $point == pxxl {
		@media (min-width: 1640px) { @content; }
	}
	@else if $point { // allows custom breakpoints
		@media (min-width: $point) { @content; }
	}
}
// Customization Bootstrap vars from "app/libs/bootstrap/scss/_variables.scss, only before @import bootstrap variables!"
$grid-gutter-width: 20px;
$grid-columns: 12;
$grid-breakpoints: (xs: 0, sm: 480px, md: 768px, lg: 1000px, xl: 1240px, xxl: 1640px);
$container-max-widths: (sm: 480px, md: 768px, lg: 1000px, xl: 1240px, xxl: 1640px);

$gutter: $grid-gutter-width;
@import 'fonts';
@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import 'reset';

//@import '../../node_modules/bootstrap/scss/bootstrap-grid.scss';
//@import '../../node_modules/bootstrap/scss/variables';

html {
	font-size: 20px;
}
body {
	min-width: 360px;
	color: $color-white;
	font-family: $font1;
	&::before {
		content: '';
		display: block;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		background: url(../img/bg.jpg) center/cover no-repeat;
		transition: 0.2s linear;
	}
	&.blured {
		overflow: hidden;
		&::before {
			top: -100px;
			right: -100px;
			bottom: -100px;
			left: -100px;
			filter: blur(15px);
		}
	}
	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-track {
		background-color: #1e1e1e;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #4b4b4b;
		border-radius: 3px;
	}
}

.container {
	padding-right: 20px;
	padding-left: 20px;
}

.bg-layout-cover {
	background: center/cover no-repeat;
}
.bg-layout-contain {
	background: center/contain no-repeat;
}
.fit-cover {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.fit-contain {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.svg-icon {
	display: block;
}

.op0 {
	opacity: 0;
}

strong {
	font-weight: 600;
}

.blur-bg {
	overflow: hidden;
	background-color: rgba(255, 255, 255, 0.2);
	backdrop-filter: blur(30px);
}
.blur-bg2 {
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(30px);
}

.w-info {
	font-size: 24px;
	.w-pict {
		height: 54px;
		margin-right: 5px;
		@include breakpoint(psm) {
			margin-right: 10px;
		}
	}
}

// animation
@keyframes jump {
	0%, 20%, 50%, 80%, 100% {
		transform: translateX(0);
	}
	40% {
		transform: translateX(5px);
	}
	60% {
		transform: translateX(3px);
	}
}
@keyframes fadeOut {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 1;
	}
	100% {
		opacity: 1;
		transform: translateY(0px);
	}
	//90% {
	//	opacity: 1;
	//	transform: translateY(0px);
	//}
	//99% {
	//	opacity: 0;
	//	transform: translateY(-25px);
	//}
	//100% {
	//	opacity: 0;
	//}
}
// </animation

// main
.enter-block {
	position: fixed;
	top: 0;
	right: 0;
	height: 100%;
	width: 100%;
	z-index: 5;
	text-align: center;
}
.enter-screen {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 100%;
	z-index: 2;
	cursor: pointer;
	.w-info {
		margin-bottom: 25px;
	}
	.clock {
		font-size: 72px;
		@include breakpoint(pmd) {
			font-size: 110px;
		}
	}
	.e-img {
		width: 54px;
		height: 54px;
		background: none;
		margin-top: 40px;
		animation: jump 2s infinite;
		@include breakpoint(pmd) {
			width: 84px;
			height: 84px;
			margin-top: 70px;
		}
	}
}
.pin-screen {
	position: absolute;
	top: -30%;
	right: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	opacity: 0;
	transition: opacity linear 0.25s, top ease 0.75s;
	&.downed {
		top: 0;
		opacity: 1;
	}
	.pin-screen-inner {
		height: 100%;
	}
	.h1 {
		font-size: 26px;
		margin-bottom: 35px;
		@include breakpoint(psm) {
			font-size: 32px;
		}
		@include breakpoint(pmd) {
			font-size: 42px;
			margin-bottom: 50px;
		}
		span {
			white-space: nowrap;
		}
		i {
			display: inline-block;
			font-style: normal;
			opacity: 0;
			transition: 0.35s ease;
			transition-delay: 0.5s;
			&:nth-child(even) {
				transform: translateY(300px);
			}
			&:nth-child(odd) {
				transform: translateY(-300px);
			}
			&.mv0 {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}
	.p-pin {
		font-family: $font2;
		font-size: 15px;
		font-weight: 300;
		margin-bottom: 25px;
		@include breakpoint(psm) {
			font-size: 17px;
		}
		@include breakpoint(pmd) {
			font-size: 20px;
		}
	}
	.pin-place {
		flex-shrink: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 60px;
		height: 75px;
		font-size: 26px;
		border-radius: 12px;
		margin: 0 4px;
		@include breakpoint(psm) {
			width: 80px;
			height: 100px;
			font-size: 32px;
			border-radius: 20px;
			margin: 0 7px;
		}
		@include breakpoint(pmd) {
			width: 120px;
			height: 150px;
			font-size: 42px;
		}
		span {
			display: block;
			opacity: 0;
			transition: opacity 0.4s;
		}
		&.entered {
			background-color: rgba(255, 255, 255, 0.5);
			span {
				opacity: 1;
			}
		}
		&:nth-child(1) {
			transition-delay: 0.4s;
			span {
				transition-delay: 0.4s;
			}
		}
		&:nth-child(2) {
			transition-delay: 0.8s;
			span {
				transition-delay: 0.8s;
			}
		}
		&:nth-child(3) {
			transition-delay: 1.2s;
			span {
				transition-delay: 1.2s;
			}
		}
		&:nth-child(4) {
			transition-delay: 1.6s;
			span {
				transition-delay: 1.6s;
			}
		}
	}
}

.main-cont {
	background: linear-gradient(180deg, rgba(6, 4, 51, 0.1) 26.46%, #060433 70%);
	padding: 30px 0 60px 0;
	transition: 0.25s, opacity 0.25s linear 0.1s;
	@include breakpoint(pmd) {
		padding: 40px 0 100px 0;
	}
	@include breakpoint(pxl) {
		padding: 120px 0 200px 0;
	}
	&.invis {
		transform: translateY(-200px);
		opacity: 0;
	}
}
.top-block {
	.right-bl {
		max-height: 320px;
		overflow: hidden;
		margin-top: 40px;
		transition: 0.2s linear;
		@include breakpoint(pxl) {
			margin-top: 0;
		}
	}
	.clock-bl {
		margin-bottom: 20px;
		#time-value2 {
			display: block;
			font-size: 72px;
			margin-right: 10px;
			@include breakpoint(psm) {
				margin-right: 25px;
			}
			@include breakpoint(pmd) {
				font-size: 110px;
			}
		}
		.w-info {
			margin-bottom: 8px;
			@include breakpoint(pmd) {
				margin-bottom: 17px;
			}
		}
	}
	h1 {
		font-size: 20px;
		@include breakpoint(pmd) {
			font-size: 24px;
		}
	}
	.main-menu {
		font-size: 17px;
		@include breakpoint(pmd) {
			font-size: 20px;
		}
		li {
			margin-top: 10px;
			margin-right: 10px;
			.menu-link {
				display: block;
				color: $color-white;
				padding: 13px 20px 11px 20px;
				border-radius: 22px;
				@include breakpoint(pmd) {
					padding: 13px 30px 11px 30px;
				}
				&:hover {
					background-color: rgba(255, 255, 255, 0.4);
				}
			}
		}
	}
	.reminder {
		display: flex;
		align-items: center;
		width: 100%;
		color: $color-white;
		font-family: $font2;
		font-size: 17px;
		font-weight: 300;
		text-align: left;
		padding: 18px;
		border-radius: 28px;
		opacity: 0;
		@include breakpoint(pmd) {
			width: 436px;
			font-size: 20px;
		}
		.text-segm {
			display: block;
			line-height: 1.2;
			margin-left: 12px;
		}
		+ .reminder {
			margin-top: 10px;
		}
		&.anim {
			animation: fadeOut 3s linear forwards;
			&:nth-child(1) {
				animation-duration: 4s;
				animation-delay: 4s;
			}
			&:nth-child(2) {
				animation-duration: 5s;
				animation-delay: 4.3s;
			}
			&:nth-child(3) {
				animation-duration: 6s;
				animation-delay: 4.6s;
			}
		}
	}
}
.section {
	padding-top: 40px;
	margin-top: 40px;
	@include breakpoint(plg) {
		padding-top: 50px;
		margin-top: 50px;
	}
	h2 {
		font-size: 36px;
		margin-bottom: 30px;
		@include breakpoint(psm) {
			font-size: 42px;
		}
		@include breakpoint(plg) {
			margin-bottom: 40px;
		}
	}
}
.imbal {
	.tab {
		height: 100%;
		color: $color-black;
		font-size: 24px;
		padding: 20px;
		border-radius: 28px;
		transition: transform linear 0.25s;
		cursor: default;
		@include breakpoint(psm) {
			padding: 30px;
		}
		@include breakpoint(plg) {
			min-height: 148px;
		}
		@include breakpoint(pxl) {
			min-height: 235px;
		}
		&.tabbg1 {
			background-image: url(../img/tabbg1.jpg);
		}
		&.tabbg2 {
			background-image: url(../img/tabbg2.jpg);
		}
		&.tabbg3 {
			background-image: url(../img/tabbg3.jpg);
		}
		&.tabbg4 {
			background-image: url(../img/tabbg4.jpg);
		}
		.tab-text {
			transition: transform linear 0.25s;
		}
		.number {
			flex-shrink: 0;
			display: block;
			width: 70px;
			color: $color-white;
			font-size: 42px;
			margin-right: 10px;
			margin-bottom: 15px;
		}
		.heading {
			margin-bottom: 15px;
		}
		.descr {
			font-family: $font2;
			font-size: 17px;
			line-height: 1.33;
		}
		&:hover {
			transform: translateY(-5px);
			.tab-text {
				transform: translateY(-10px);
			}
		}
	}
}
.what {
	.tab {
		display: flex;
		align-items: center;
		height: 120px;
		padding: 20px 30px;
		border-radius: 28px;
		transition: transform linear 0.25s;
		cursor: default;
		@include breakpoint(pmd) {
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			min-height: 264px;
			height: 100%;
			padding: 30px;
		}
		@include breakpoint(plg) {
			min-height: 234px;
		}
		@include breakpoint(pxl) {
			min-height: 294px;
			padding: 30px 20px;
		}
		@include breakpoint(pxxl) {
			padding: 30px;
		}
		.img-wr {
			width: 80px;
		}
		.descr-gr {
			margin-left: 30px;
			transition: transform linear 0.25s;
			@include breakpoint(pmd) {
				margin-left: 0;
			}
		}
		.descr {
			font-family: $font2;
			font-size: 17px;
			margin-bottom: 15px;
			opacity: 0.66;
		}
		.big-p {
			color: $color1;
			font-family: $font1;
			font-size: 24px;
		}
		&:hover {
			transform: translateY(-5px);
			.descr-gr {
				transform: translateY(-10px);
			}
		}
	}
}
.it-saas {
	.scroll-sect {
		width: 100%;
		overflow-x: auto;
		padding-top: 5px;
		cursor: grab;
		user-select: none;
		//scroll-behavior: smooth;
		&::-webkit-scrollbar {
			height: 0;
		}
	}
	.tab-wr {
		flex-shrink: 0;
		padding-right: 10px;
		padding-left: 10px;
	}
	.tab {
		width: 300px;
		min-height: 650px;
		height: 100%;
		border-radius: 28px;
		transition: transform linear 0.25s;
		@include breakpoint(psm) {
			width: 347px;
		}
		@include breakpoint(plg) {
			width: 385px;
			min-height: 630px;
		}
		&.tabbg5 {
			background-image: url(../img/tabbg5.jpg);
		}
		&.tabbg6 {
			background-image: url(../img/tabbg6.jpg);
		}
		&.tabbg7 {
			background-image: url(../img/tabbg7.jpg);
		}
		&.tabbg8 {
			background-image: url(../img/tabbg8.jpg);
		}
		.top-part {
			color: $color-black;
			font-size: 15px;
			padding: 30px;
			@include breakpoint(psm) {
				font-size: 17px;
			}
			.stick {
				display: inline-block;
				color: $color-white;
				padding: 15px 20px 13px 20px;
				border-radius: 23px;
				margin-bottom: 20px;
			}
			.heading {
				font-size: 24px;
				margin-bottom: 10px;
			}
			.descr-p {
				font-family: $font2;
				line-height: 1.33;
			}
		}
		.btm-part {
			width: 100%;
			padding: 10px;
			.scr-wr {
				position: relative;
				height: 353px;
				overflow: hidden;
				border-radius: 20px;
				img {
					pointer-events: none;
					transition: transform linear 0.2s;
				}
			}
			.blue-bg-link {
				display: flex;
				justify-content: space-between;
				align-items: center;
				position: absolute;
				right: 20px;
				bottom: 20px;
				left: 20px;
				z-index: 5;
				height: 85px;
				overflow: hidden;
				background: rgba(26, 159, 255, 0.7);
				backdrop-filter: blur(30px);
				padding: 15px 30px;
				border-radius: 20px;
			}
		}
		&:hover {
			transform: translateY(-5px);
			.btm-part {
				.scr-wr {
					> img {
						transform: scale(1.05);
					}
				}
			}
		}
	}
}
.btm-section {
	margin-top: 50px;
	@include breakpoint(psm) {
		margin-top: 80px;
	}
	@include breakpoint(pmd) {
		margin-top: 100px;
	}
	.f-menu-wr {
		position: relative;
		.up {
			display: block;
			width: 54px;
			height: 54px;
			margin: 20px auto 0 auto;
			@include breakpoint(pmd) {
				width: 84px;
				height: 84px;
				margin: 0 0 0 40px;
			}
			@include breakpoint(plg) {
				position: absolute;
				top: 50%;
				right: -124px;
				z-index: 1;
				transform: translateY(-50%);
			}
		}
	}
	.f-menu {
		padding: 12px;
		border-radius: 18px;
		@include breakpoint(pmd) {
			padding: 16px 18px;
			border-radius: 28px;
		}
		li {
			position: relative;
			+ li {
				margin-left: 7px;
				@include breakpoint(pmd) {
					margin-left: 28px;
				}
			}
			.square {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 54px;
				height: 54px;
				position: relative;
				background-color: $color-white;
				border-radius: 14px;
				@include breakpoint(pmd) {
					width: 80px;
					height: 80px;
					border-radius: 20px;
				}
				img {
					width: 44px;
					height: 44px;
					@include breakpoint(pmd) {
						width: 65px;
						height: 65px;
					}
				}
				#date {
					position: absolute;
					top: 26px;
					right: 50%;
					transform: translateX(50%);
					z-index: 1;
					font-size: 14px;
					@include breakpoint(pmd) {
						top: 38px;
						font-size: 20px;
					}
				}
			}
			.dig {
				display: flex;
				justify-content: center;
				align-items: center;
				min-width: 24px;
				height: 24px;
				position: absolute;
				top: -8px;
				right: -8px;
				z-index: 2;
				font-size: 14px;
				background: linear-gradient(110.73deg, #FF6161 33.24%, #F52828 111.73%);
				padding-top: 2px;
				border-radius: 50%;
				@include breakpoint(pmd) {
					min-width: 36px;
					height: 36px;
					top: -14px;
					right: -14px;
					font-size: 20px;
				}
			}
		}
	}
}
/* </main */

/* modal */
.modal {
	transition: 0.5s;
}
.modal-backdrop.show {
	opacity: 1;
	background-color: rgba(6, 4, 51, 0.6);
	backdrop-filter: blur(30px);
}
.contacts,
.weather {
	.modal-dialog {
		@include breakpoint(psm) {
			max-width: 440px;
		}
		@include breakpoint(pmd) {
			max-width: 728px;
		}
		@include breakpoint(plg) {
			max-width: 960px;
		}
		@include breakpoint(pxl) {
			max-width: 1070px;
		}
	}
}
.weather {
	.modal-dialog {
		@include breakpoint(plg) {
			max-width: 960px;
		}
		@include breakpoint(pxxl) {
			max-width: 1460px;
		}
	}
}
.modal-content {
	background-color: transparent;
	border: none;
}
.modal-header {
	border: none;
}
.modal-title {
	font-size: 32px;
	text-align: center;
	@include breakpoint(pmd) {
		font-size: 42px;
	}
}
.modal-body {
	padding: 0;
}
.contacts {
	@include breakpoint(pmd) {
		min-height: 480px;
	}
	.nav-link {
		color: $color-white;
		background-color: transparent;
		+ .nav-link {
			margin-top: 20px;
			@include breakpoint(plg) {
				margin-top: 30px;
			}
		}
		&.active {
			background-color: #1d74ff;
		}
	}
	.part-left {
		width: 43%;
		font-size: 17px;
		padding: 20px;
		border-radius: 20px 0 0 20px;
		@include breakpoint(plg) {
			width: 35%;
			font-size: 20px;
		}
		@include breakpoint(pxl) {
			padding: 30px;
		}
	}
	.part-right,
	.hid-part {
		width: 57%;
		color: $color-black;
		background-color: $color-white;
		padding: 20px;
		border-radius: 0 20px 20px 0;
		@include breakpoint(plg) {
			width: 65%;
			padding: 30px;
		}
		.heading {
			margin-bottom: 48px;
		}
		.img-wr {
			flex-shrink: 0;
			width: 100px;
			height: 100px;
			overflow: hidden;
			border-radius: 50%;
			margin-right: 20px;
		}
		h5 {
			font-size: 24px;
		}
		.descr-el {
			margin-bottom: 48px;
		}
		.pict-el {
			display: block;
			font-size: 17px;
			text-align: center;
			margin-right: 30px;
			img {
				margin-bottom: 10px;
			}
		}
		.table-el {
			width: 100%;
			font-family: $font2;
			font-weight: 400;
			font-size: 15px;
			@include breakpoint(psm) {
				font-size: 17px;
			}
			tr:last-child {
				td {
					border: none;
				}
			}
			td {
				border-bottom: 1px solid #c9c9c9;
			}
			td:first-child {
				width: 130px;
				color: #9b9b9b;
				text-align: right;
				padding: 8px 5px 8px 0;
				@include breakpoint(psm) {
					width: 38%;
					padding: 8px 8px 8px 0;
				}
				@include breakpoint(pmd) {
					width: 40%;
				}
				@include breakpoint(plg) {
					width: 30%;
				}
				@include breakpoint(pxl) {
					width: 25%;
				}
			}
			td:last-child {
				padding: 8px 0 8px 5px;
				@include breakpoint(psm) {
					padding: 8px 0 8px 8px;
				}
			}
		}
	}
}
.accordion {
	padding: 20px;
	border-radius: 20px;
	li {
		+ li {
			margin-top: 10px;
		}
	}
	.li-head {
		display: inline-block;
		color: $color-white;
		font-size: 17px;
		padding: 10px 20px;
		border-radius: 5px;
		&.active {
			background-color: #1d74ff;
		}
	}
	.hid-part {
		display: none;
		width: auto;
		background-color: $color-white;
		border-radius: 0;
		margin: 20px -20px 0 -20px;
		h5 {
			color: $color-black;
		}
		.heading,
		.descr-el {
			margin-bottom: 20px;
		}
	}
}
.weather {
	.w-tab-wr {
		padding: 5px;
		@include breakpoint(pmd) {
			width: 14.285%;
		}
	}
	.w-tab {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 10px 10px 20px;
		border-radius: 20px;
		@include breakpoint(pmd) {
			flex-direction: column;
			align-items: flex-start;
			height: 210px;
			padding: 20px;
		}
		@include breakpoint(pxxl) {
			height: 294px;
			padding: 30px;
		}
	}
	.flex-place {
		order: 2;
		@include breakpoint(pmd) {
			flex-grow: 1;
			order: 0;
		}
	}
	.img-wr {
		width: 54px;
		height: 54px;
		@include breakpoint(pmd) {
			width: 64px;
			height: 64px;
			transform: translateY(-10px) translateX(-10px);
		}
		@include breakpoint(pxxl) {
			width: 84px;
			height: 84px;
			transform: translateY(-15px) translateX(-15px);
		}
	}
	.week-day {
		flex-grow: 1;
		font-family: $font2;
		font-weight: 300;
		font-size: 17px;
		opacity: 0.66;
		order: 0;
		@include breakpoint(pmd) {
			flex-grow: 0;
			order: 1;
		}
	}
	.temp {
		order: 1;
		font-size: 20px;
		padding-top: 4px;
		margin-right: 25px;
		@include breakpoint(pmd) {
			order: 2;
			padding-top: 0;
			margin-top: 15px;
			margin-right: 0;
		}
		@include breakpoint(plg) {
			font-size: 24px;
		}
	}
}
/* </modal */
